var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createAsyncThunk } from '@reduxjs/toolkit';
import { endOfDay, startOfDay } from 'date-fns';
import axios from '../../../utils/axios';
export var getMyCalls = createAsyncThunk('/calls/me', function () { return __awaiter(void 0, void 0, void 0, function () {
    var data, response, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                return [4 /*yield*/, axios.get('/calls/me')];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.data];
            case 2:
                data = _a.sent();
                if (response.status === 200) {
                    return [2 /*return*/, data.data];
                }
                throw new Error(response.statusText);
            case 3:
                error_1 = _a.sent();
                return [2 /*return*/, Promise.reject(error_1.message ? error_1.message : data === null || data === void 0 ? void 0 : data.message)];
            case 4: return [2 /*return*/];
        }
    });
}); });
export var createCallsToday = createAsyncThunk('/calls/create', function (newCall) { return __awaiter(void 0, void 0, void 0, function () {
    var data, response, error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                return [4 /*yield*/, axios.post('/calls', newCall)];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.data];
            case 2:
                data = _a.sent();
                if (response.status === 200) {
                    return [2 /*return*/, data];
                }
                throw new Error(response.statusText);
            case 3:
                error_2 = _a.sent();
                return [2 /*return*/, Promise.reject(error_2.message ? error_2.message : data.message)];
            case 4: return [2 /*return*/];
        }
    });
}); });
export var updateCall = createAsyncThunk('/calls/update', function (_a) {
    var newCall = _a.newCall;
    return __awaiter(void 0, void 0, void 0, function () {
        var data, response, error_3;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axios.patch("/calls/".concat(newCall._id), {
                            date: newCall.date,
                            calls: newCall === null || newCall === void 0 ? void 0 : newCall.calls,
                        })];
                case 1:
                    response = _b.sent();
                    data = response.data;
                    if (response.status === 200) {
                        return [2 /*return*/, data];
                    }
                    throw new Error(response.statusText);
                case 2:
                    error_3 = _b.sent();
                    return [2 /*return*/, Promise.reject(error_3.message ? error_3.message : data.message)];
                case 3: return [2 /*return*/];
            }
        });
    });
});
export var getCallByDate = createAsyncThunk('/calls/date', function (_a) {
    var date = _a.date;
    return __awaiter(void 0, void 0, void 0, function () {
        var data, response, error_4;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axios.get("/calls/date?date=".concat(date))];
                case 1:
                    response = _b.sent();
                    data = response.data;
                    if (response.status === 200) {
                        return [2 /*return*/, data.data];
                    }
                    throw new Error(response.statusText);
                case 2:
                    error_4 = _b.sent();
                    return [2 /*return*/, Promise.reject(error_4.message ? error_4.message : data.message)];
                case 3: return [2 /*return*/];
            }
        });
    });
});
export var getCallById = createAsyncThunk('/calls/id', function (_a) {
    var id = _a.id;
    return __awaiter(void 0, void 0, void 0, function () {
        var data, response, error_5;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axios.get("/calls/".concat(id))];
                case 1:
                    response = _b.sent();
                    data = response.data;
                    if (response.status === 200) {
                        return [2 /*return*/, data.data];
                    }
                    throw new Error(response.statusText);
                case 2:
                    error_5 = _b.sent();
                    return [2 /*return*/, Promise.reject(error_5.message ? error_5.message : data.message)];
                case 3: return [2 /*return*/];
            }
        });
    });
});
export var getAllCall = createAsyncThunk('/calls/all', function (payload) { return __awaiter(void 0, void 0, void 0, function () {
    var data, page, limit, startDate, endDate, selectedUser, selectedOffice, view, order, orderBy, params, response, error_6;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                page = payload.page, limit = payload.limit, startDate = payload.startDate, endDate = payload.endDate, selectedUser = payload.selectedUser, selectedOffice = payload.selectedOffice, view = payload.view, order = payload.order, orderBy = payload.orderBy;
                params = __assign(__assign(__assign(__assign(__assign({ page: page + 1, limit: limit }, (selectedUser ? { user: selectedUser._id } : {})), (selectedOffice ? { office: selectedOffice._id } : {})), (view !== 'all' ? { period: view } : {})), (startDate && endDate ? { startDate: startDate, endDate: endDate } : {})), (order ? { sort: order === 'desc' ? "-".concat(orderBy) : "".concat(orderBy) } : {}));
                if (startDate && endDate) {
                    params.startDate = startOfDay(new Date(startDate));
                    params.endDate = endOfDay(new Date(endDate));
                }
                _a.label = 1;
            case 1:
                _a.trys.push([1, 4, , 5]);
                return [4 /*yield*/, axios.get('/calls/filter', { params: params })];
            case 2:
                response = _a.sent();
                return [4 /*yield*/, response.data];
            case 3:
                data = _a.sent();
                if (response.status === 200) {
                    return [2 /*return*/, data.data];
                }
                throw new Error(response.statusText);
            case 4:
                error_6 = _a.sent();
                return [2 /*return*/, Promise.reject(error_6.message ? error_6.message : data === null || data === void 0 ? void 0 : data.message)];
            case 5: return [2 /*return*/];
        }
    });
}); });
